<template>
  <v-container id="password_reset">
    <v-row justify="center">
      <v-col sm="12">
        <v-alert :value="resetError" type="error">{{ errorMessage }}</v-alert>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4" xl="4">
        <v-card v-if="!resetError" :disabled="formLoading" :loading="formLoading">
          <v-card-title class="headline">
            Set new password
          </v-card-title>
          <v-card-text>
            <v-flex>
              <div>
                <p class="text-left">
                  Please use a secure password with <br />
                </p>
                <li class="text-left">Minimum 8 characters</li>
                <li class="text-left">Upper and lower case letters</li>
                <li class="text-left">At least one number</li>
              </div>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-flex>
                  <v-text-field v-model="newPassword" label="New Password" persistent-hint type="password"
                    :rules="[rules.required, rules.passwordCheck]"></v-text-field>
                  <v-text-field v-model="confirmPassword" label="Confirm Password" persistent-hint type="password"
                    :rules="[rules.required, rules.confirmPasswordCheck]"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="passwordSetLoading" :loading="passwordSetLoading" @click="submitPassword">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      id: this.$route.query.id,
      token: this.$route.query.token,
      newPassword: "",
      confirmPassword: "",
      resetError: false,
      errorMessage: "Error processing reset request",
      formLoading: false,
      passwordSetLoading: false,
      rules: {
        required: (value) => !!value || "Required",
        passwordCheck: (value) => {
          if (
            this.confirmPassword != "" &&
            this.confirmPassword != this.newPassword
          )
            return "new password is not matching with confirm password";
          return true;
        },
        confirmPasswordCheck: (value) => {
          if (value != this.newPassword)
            return "confirm password is not matched.";
          return true;
        },
      },
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },

  created() {
    this.formLoading = true;
    axios
      .post("/user/validate-token", { id: this.id, token: this.token })
      .then((response) => {
        if (response.status === 200) {
          this.formLoading = false;
        }
      })
      .catch((e) => {
        console.log(e);
        this.resetError = true;
        this.errorMessage = e.response.data;
        this.formLoading = false;
      });
  },

  methods: {
    submitPassword() {
      var payload = {
        id: this.id,
        token: this.token,
        password: this.newPassword,
      };
      this.passwordSetLoading = true;
      axios
        .post("/user/reset-password", payload)
        .then((response) => {
          if (response.status === 200) {
            this.snackbar.color = "success";
            this.snackbar.text = "Password Updated";
            this.snackbar.visible = true;
            this.passwordSetLoading = false;
          }
        })
        .catch((e) => {
            console.log(e);
            this.snackbar.color = "error";
            this.snackbar.text = "Password reset failed";
            this.snackbar.visible = true;
            this.passwordSetLoading = false;
        });
    },
  },
};
</script>

<style></style>
