import Vue from 'vue';
import Router from 'vue-router';

// Common Components
import Home from '../views/Home.vue';
import Login from '../components/login/Login.vue';
import PasswordReset from '@/components/login/PasswordReset.vue';
import ForgotPassword from '@/components/login/ForgotPassword.vue';

// Admin/user Componentes
import Planner from '../components/jobs/Planner.vue';
import Contractors from '../components/contractors/Contractors.vue';
import Contractor from '../components/contractors/Contractor.vue';
import ContractorRO from '../components/contractors/ro/Contractor.vue';
import Leave from '../components/contractors/Leave.vue';
import Clients from '../components/clients/Clients.vue';
import Client from '../components/clients/Client.vue';
import ABA from '../components/payroll/GenerateABA.vue';
import Miscellaneous from '../components/miscellaneous/Miscellaneous.vue';
import Payslip from '../components/payroll/Payslip.vue';
import PayslipRetrieval from '../components/payroll/PayslipRetrieval.vue';
import Invoice from '../components/invoice/Invoice.vue';
import InvoiceRetrieval from '../components/invoice/InvoiceRetrieval.vue';
import Job from '../components/jobs/Job.vue';
import AdminDashboard from '../components/dashboards/Admin.vue';
import UserDashboard from '../components/dashboards/User.vue';
import CompDetail from '../components/settings/CompanyDetail.vue';

// Client Portal Components
import clientPortalDashboard from '../components/client_portal/Dashboard.vue';
import clientPortalProfile from '../components/client_portal/Profile.vue';
import clientPortalChangePassword from '../components/client_portal/ChangePassword.vue';
import clientJob from '../components/client_portal/Job.vue';

// Contractor Portal Components
import contractorPortalDashboard from '../components/contractor_portal/Dashboard.vue';
import contractorPortalHome from '../components/contractor_portal/Home.vue';
import contractorPortalLeave from '../components/contractor_portal/Leave.vue';
import contractorPortalChangePassword from '../components/contractor_portal/ChangePassword.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',

  routes: [
    // Common Routes
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },

    {
      path: '/password-reset',
      name: 'passwordReset',
      component: PasswordReset,
    },

    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
    },
    // Application Routes (users and admins only)
    {
      path: '/contractors',
      name: 'contractors',
      component: Contractors,
    },
    // Application Routes (users and admins only)
    {
      path: '/contractorsLeave',
      name: 'contractorsLeave',
      component: Leave,
    },
    {
      path: '/contractors/:id',
      name: 'contractor',
      component: Contractor,
    },
    {
      path: '/contractors/ro/:id',
      name: 'contractorRo',
      component: ContractorRO,
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
    },
    {
      path: '/compDetail',
      name: 'compDetail',
      component: CompDetail,
    },
    {
      path: '/misc',
      name: 'misc',
      component: Miscellaneous,
    },
    {
      path: '/clients/:id',
      name: 'client',
      component: Client,
    },
    {
      path: '/aba',
      name: 'aba',
      component: ABA,
    },
    {
      path: '/payslip',
      name: 'payslip',
      component: Payslip,
    },
    {
      path: '/payslipRetrieval',
      name: 'payslipRetrieval',
      component: PayslipRetrieval,
    },
    {
      path: '/invoice',
      name: 'invoice',
      component: Invoice,
    },
    {
      path: '/invoiceRetrieval',
      name: 'invoiceRetrieval',
      component: InvoiceRetrieval,
    },
    {
      path: '/job',
      name: 'addJob',
      component: Job,
    },
    {
      path: '/job/:id',
      name: 'modifyJob',
      component: Job,
    },
    {
      path: '/admindashboard',
      name: 'adminDashboard',
      component: AdminDashboard,
    },
    {
      path: '/userdashboard',
      name: 'userdashboard',
      component: UserDashboard,
    },
    {
      path: '/planner',
      name: 'planner',
      component: Planner,
    },

    // Client Portal Routes
    {
      path: '/client_portal/dashboard',
      name: 'clientPortalDashboard',
      component: clientPortalDashboard,
    },
    {
      path: '/client_portal/profile',
      name: 'clientPortalProfile',
      component: clientPortalProfile,
    },
    {
      path: '/client_portal/password',
      name: 'clientPortalChangePassword',
      component: clientPortalChangePassword,
    },
    {
      path: '/client_portal/job',
      name: 'clientJobAdd',
      component: clientJob,
    },
    {
      path: '/client_portal/job/:id',
      name: 'clientJobModify',
      component: clientJob,
    },

    // Contractor Portal Routes
    {
      path: '/contractor_portal/dashboard',
      name: 'contractorPortalDashboard',
      component: contractorPortalDashboard,
    },
    {
      path: '/contractor_portal/home',
      name: 'contractorPortalHome',
      component: contractorPortalHome,
    },
    {
      path: '/contractor_portal/leave',
      name: 'contractorPortalLeave',
      component: contractorPortalLeave,
    },
    {
      path: '/contractor_portal/change_password',
      name: 'contractorPortalChangePassword',
      component: contractorPortalChangePassword,
    },
    // Common Routes
    {
      path: '/about',
      name: 'about',
      component: () =>
        import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
      path: '/support',
      name: 'support',
      component: () =>
        import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () =>
        import(/* webpackChunkName: "support" */ '../views/Unauthorized.vue'),
    },
    {
      path: '/404',
      name: 'notFound',
      component: () =>
        import(/* webpackChunkName: "support" */ '../views/NotFound.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});
