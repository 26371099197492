<template>
  <v-app id="inspire">
    <v-main router>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-form @submit="onSubmit" @reset="onReset">
              <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Reset password</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-alert :value="resetError" type="error">Error processing reset request</v-alert>
                  <v-alert :value="accountDisabled" type="error">Account disabled contact Administrator</v-alert>
                  <v-alert :value="resetSuccessful" type="success">Reset request submitted</v-alert>
                  <v-text-field prepend-icon="person" name="id" v-model="id" label="Username or email"
                    type="text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-layout align-center justify-space-around>
                    <v-btn type="submit" color="primary" :loading="resetLoading" :disabled="resetLoading">
                      Submit
                      <v-icon dark right>check_circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-card-actions>
                <div class="pa-5" />
                <v-layout align-center justify-center>
                  <span class="subtitle">
                    <router-link to="/login">Back to login</router-link>
                  </span>
                </v-layout>
                <div class="pa-5" />
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    id: "",
    resetError: false,
    accountDisabled: false,
    resetSuccessful: false,
    resetLoading: false,
    token: "",
  }),

  mounted() {
    this.resetError = false;
    this.accountDisabled = false;
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.resetLoading = true;
      axios

        .post("/user/forgot-password", { identity: this.id })
        .then((response) => {
          if (response.status === 200) {
            this.resetError = false;
            this.accountDisabled = false;
            this.resetSuccessful = true;
            this.resetLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 401) {
              this.accountDisabled = true;
            } else {
              this.resetError = true;
            }
            this.resetLoading = false;
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.id = "";
    },
  },
  props: {
    source: String,
  },
};
</script>
