<template>
  <v-layout>
    <v-flex sm12 md12>
      <v-card>
        <v-card-title v-if="!newClient" class="headline" title--text>
          {{ client.Name }}
        </v-card-title>

        <v-card-text>
          <v-form ref="client" readonly :key="forceUpdate">
            <v-text-field v-if="newClient" label="Depot Name" counter="20" v-model="client.DepotName"
              @input="checkDuplicate()" :rules="[rules.checkDepotName]"></v-text-field>
            <v-text-field counter="45" label="Name" v-model="client.Name"
              :rules="[rules.required, rules.DepotName]"></v-text-field>
            <v-text-field counter="45" label="Contact Person" v-model="client.ContactPerson"
              :rules="[rules.required]"></v-text-field>
            <v-layout>
              <v-flex xs12 md6>
                <v-text-field counter="11" label="ABN" v-model="client.ABN" :rules="[rules.ABN]"></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field counter="9" label="ACN" v-model="client.ACN" :rules="[rules.ACN]"></v-text-field>
              </v-flex>
            </v-layout>
            <v-text-field counter="50" label="Address" v-model="client.Address1"></v-text-field>
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field counter="20" label="Suburb" v-model="client.Suburb"></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select counter="15" v-model="client.State" :items="states" label="State"></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field counter="4" label="Postcode" v-model="client.Postcode"></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md6>
                <v-text-field label="Phone" v-model="client.Phone"></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field label="Mobile" v-model="client.Mobile"></v-text-field>
              </v-flex>
            </v-layout>
            <v-text-field readonly label="Invoice Email" counter="256" v-model="client.email"
              :rules="[rules.required, rules.email]"></v-text-field>

            <v-text-field label="Login (email)" counter="256" v-model="clientUsername"
              :rules="[rules.required, rules.email]"></v-text-field>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md6>
                <v-text-field readonly label="Terms" v-model="client.terms" counter="2"
                  :rules="[rules.required, rules.terms]"></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field readonly label="Fuel Levy" v-model="client.fuel_levy"
                  :rules="[rules.fuel_levy]"></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <!-- should we allow clients to update their details??? -->
        <!-- <v-card-actions>
<v-spacer/>
          <v-btn right v-if="newClient" color="success" dark @click="addClient">
            Add
            <v-icon right dark>save</v-icon>
          </v-btn>

          <v-btn v-if="!newClient" color="success" dark @click="saveClient">
            Update
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-flex>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>

  </v-layout>
</template>

<script>
import axios from "axios";
import router from "../../router";

export default {
  props: {
    depotName: String,
    newClient: Boolean,
    dialog: Boolean,
  },

  data() {
    return {
      client: {},
      clientUsername: '',
      forceUpdate: 0,
      showDialog: true,
      states: ["VIC", "NSW", "TAS", "QLD", "SA", "WA", "NT"],
      errors: [],
      uniqID: false,
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      rules: {
        required: (value) => !!value || "Required",
        checkDepotName: (id) => {
          return this.uniqId || "ID already in use";
        },
        DepotName: (value) => {
          const pattern = /^(?!.*(\(|\))).*/; // not include ( or )
          return pattern.test(value) || "Depot Name should not include ( or )";
        },
        ABN: (value) => {
          const pattern = /^([0-9]*)$/;
          if (typeof value != "undefined") {
            if (value.length > 0) {
              if (pattern.test(value)) {
                var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
                var sum = 0;
                for (var i = 0; i < weights.length; i++) {
                  if (i == 0) {
                    sum = (value[i] - 1) * weights[i];
                  } else {
                    sum += value[i] * weights[i];
                  }
                }
                if (sum % 89 != 0) return "Invalid ABN";
                return true;
              }
              return "ABN must be in 11 digits and no space";
            }
            return true;
          }
          return true;
        },
        ACN: (value) => {
          const pattern = /^([0-9]*)$/;
          if (typeof value != "undefined") {
            if (value.length > 0) {
              if (pattern.test(value)) {
                var testvalue =
                  parseInt(value[0]) * 8 +
                  value[1] * 7 +
                  value[2] * 6 +
                  value[3] * 5 +
                  value[4] * 4 +
                  value[5] * 3 +
                  value[6] * 2 +
                  parseInt(value[7]);
                var modulus = testvalue % 10;
                if (10 - modulus != value[8]) {
                  return "Invalid ACN";
                }
                return true;
              }
              //console.log(pattern.test(value));
              return "ACN must be 9 digits and no spaces";
            }
            return true;
          }
          return true;
        },
        Postcode: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Invalid Postcode";
        },
        Phone: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Invalid phone";
        },
        Mobile: (value) => {
          const pattern = /^04[0-9]*$/;
          return pattern.test(value) || "Invalid mobile";
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        terms: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Invalid terms";
        },
        fuel_levy: (value) => {
          const pattern = /^([0-9]*\.*[0-9]*)$/;
          return (
            pattern.test(value) || "fuel levy need to be in decimal number"
          );
        },
      },
    };
  },
  created() {
    this.loadClient();
    this.loadClientUsers();
  },
  computed: {},

  watch: {},

  methods: {
    loadClient() {
      axios
        .get("/client_routes/client")
        .then((response) => {
          this.client = response.data[0];
          if (this.client.show_name == 1) {
            this.client.show_name = true;
          } else {
            this.client.show_name = false;
          }
          if (this.client.Inactive == 1) {
            this.client.Inactive = true;
          } else {
            this.client.Inactive = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadClientUsers() {
      axios
        .get("/client_routes/users")
        .then((response) => {
          if (response.data.length > 0) {
            this.clientUsername = response.data[0].username
          }
          else {
            this.clientUsername = ''
          }
        })
        .catch((e) => {
          this.errors.push(e)
        });
    },
    saveClient() {
      if (this.$refs.client.validate()) {
        if (typeof this.client.show_name === "undefined") {
          this.client.show_name = 0;
        } else if (this.client.show_name == false) {
          this.client.show_name = 0;
        } else {
          this.client.show_name = 1;
        }
        if (this.client.Inactive == false) {
          this.client.Inactive = 0;
        } else {
          this.client.Inactive = 1;
        }
        var payload = { data: this.client };
        axios
          .post("/client/updateClient", payload)
          .then((response) => {
            if (response.status === 200) {
              this.updateClientUser(this.clientUsername)
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    updateClientUser(username) {
      axios.get("/user/" + username)
        .then((res) => {
          if (res.data.length > 0 && res.data[0].DepotName !== this.client.DepotName) {
            this.snackbar.color = "warning";
            this.snackbar.text = "Login username already associated with another client";
            this.snackbar.visible = true;
          }
          else {
            axios
              .put('/user/clientuser/' + this.client.DepotName + '/' + username)
              .then((res) => {
                if (res.status === 200) {
                  this.snackbar.color = "success";
                  this.snackbar.text = "Client Updated";
                  this.snackbar.visible = true;
                }
              })
              .catch((e) => {
                console.log("Error updating client user: " + e)
              });

          }
        })
        .catch((e) => {
          console.log("Error checking existing user " + e)
        })
    },
    clientCancel() {
      //router.push({path:'/clients'});
      this.showDialog = false;
    },
    resetPassword() {
      let user = {
        username: this.clientUsername
      };

      axios
        .put("/user/reset-password", user)
        .then((res) => {
          if (res.status === 200) {
            this.snackbar.color = "success";
            this.snackbar.text = "Password reset completed";
            this.snackbar.visible = true;
            this.Password = "";
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },
  },
};
</script>
