import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure your project is capable of handling css files
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
import settings from '../settings.json';

import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
axios.defaults.baseURL = settings.apiUrl;

export default new Vuetify({
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
});

const opts = {
  // theme: {
  //   dark: true,
  // }
};

Vue.config.productionTip = false;

store.dispatch('tryAutoLogin').then(() => {
  new Vue({
    vuetify: new Vuetify(opts),
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});

// Create an Axios instance
const api = axios.create({
  // Your API base URL
  baseURL: settings.apiUrl,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // Redirect to /login
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  const adminOnly = ['/admindashboard', '/aba'];
  const publicRoutes = [
    '/',
    '/login',
    '/support',
    '/password-reset',
    '/forgot-password',
    '/unauthorized',
  ];

  const ruserPaths = ['/contractorsLeave', '/contractors'];

  const now = Date.now() / 1000;
  if (!publicRoutes.includes(to.path)) {
    if (!store.getters.isAuthenticated(now)) next('/login');
    else if (adminOnly.includes(to.path)) {
      if (store.getters.user.role !== 'admin') next('/unauthorized');
      else next();
    } else if (store.getters.user.role === 'contractor') {
      if (!to.path.startsWith('/contractor_portal')) {
        next('/unauthorized');
      } else {
        next();
      }
    } else if (store.getters.user.role === 'client') {
      if (!to.path.startsWith('/client_portal')) {
        next('/unauthorized');
      } else {
        next();
      }
    } else if (store.getters.user.role === 'ruser') {
      if (
        !ruserPaths.includes(to.path) &&
        !to.path.startsWith('/contractors/ro/')
      ) {
        next('/unauthorized');
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});
